import { configureStore } from "@reduxjs/toolkit";
import themeSlice from "./themeSlice";
import hamSlice from "./hamSlice";

const store = configureStore({
  reducer: {
    theme: themeSlice,
    ham: hamSlice,
  },
});

export default store;
