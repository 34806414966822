import { useSelector } from "react-redux";

const WallpaperCard = ({ url, link }) => {
  const isDark = useSelector((store) => store.theme.darkMode);
  return (
    <div
      className={`w-[350px] m-4 rounded ${
        isDark ? "border-4 border-slate-50" : "border-2 border-y-slate-950"
      } transition ease delay-50 hover:-translate-y-1 hover:scale-110 duration-50`}
    >
      <a href={link} className="" download target="_blank" rel="noreferrer">
        <img className="rounded" src={url} alt="Wallpaper" />
      </a>
    </div>
  );
};

export default WallpaperCard;
