import ProjectCard from "./ProjectCard";
import data from "../utils/constants";

const Project = () => {
  return (
    <div>
      <div className="m-5">
        {data.map((card) => (
          <ProjectCard data={card} key={card?.id} />
        ))}
      </div>
    </div>
  );
};

export default Project;
