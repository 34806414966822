const About = () => {
  const name = "Shobhit Nautiyal";
  return (
    <>
      <div className="flex flex-col justify-center items-center px-6 mx-auto max-w-screen-xl md:px-16 lg:px-36">
        <div className="text-center">
          <div className="py-2 mt-6">
            <h1 className="text-6xl lg:text-8xl">Hi, I'am</h1>
          </div>
          <div className="py-2">
            <h1>
              {name.split("").map((char, index) => (
                <span
                  key={index}
                  className={`font-extrabold inline-block opacity-0 animate-typewriter`}
                  style={{ animationDelay: `${index * 0.1}s` }}
                >
                  <span className="text-7xl lg:text-8xl">
                    {char === " " ? "\u00A0" : char}
                  </span>
                </span>
              ))}
            </h1>
          </div>
          <div className="py-6">
            <h1 className="">
              <p className="text-3xl leading-snug">{"<web Developer />"}</p>
              <p className="text-3xl leading-snug">{"<coding Instructor />"}</p>
              <p className="text-3xl leading-snug">{"<mentor />"}</p>
            </h1>
          </div>
        </div>
      </div>
      <div className="pb-5 flex justify-center items-center px-6 mx-auto max-w-screen-xl md:px-16 lg:px-36">
        <p className="m-1 text-base text-balance text-center">
          A dedicated and passionate coding instructor with extensive experience
          in teaching ReactJS and JavaScript at a school. With a robust
          background in web development and a flair for mentoring, I enjoy
          helping students navigate the complexities of programming and become
          confident, proficient developers.
        </p>
      </div>
    </>
  );
};

export default About;
