import { useSelector } from "react-redux";

const ProjectCard = ({ data }) => {
  const { title, description, github, link, image, techStack } = data;

  const isDark = useSelector((store) => store.theme.darkMode);

  return (
    <div
      className={`flex flex-col md:flex-row rounded-lg overflow-hidden m-6 p-4 max-w-screen-lg mx-auto ${
        isDark ? "shadow-2xl shadow-slate-600" : "shadow-2xl shadow-slate-500"
      } `}
    >
      <div className="w-full md:w-[350px] h-[250px] flex-shrink-0 mb-4 md:mb-0">
        <img
          src={image}
          alt="thumbnail"
          className="w-full h-full rounded-lg shadow-xl object-fill"
        />
      </div>
      <div className="p-4 flex flex-col justify-between flex-1">
        <div>
          <h1 className="text-2xl font-semibold mb-2">{title}</h1>
          <p className="mb-4">{description}</p>
        </div>
        <div className="flex flex-wrap gap-2 mb-4">
          {techStack.map((item) => (
            <span
              key={item}
              className="bg-gray-200 text-gray-700 py-1 px-3 rounded-full text-sm"
            >
              {item}
            </span>
          ))}
        </div>
        <div className="mt-4 flex justify-start">
          <a
            href={github}
            className="rounded-3xl font-medium mr-4 p-1 pr-2 pl-2 border border-indigo-500 bg-indigo-500 text-slate-50 hover:bg-indigo-700"
            target="_blank"
            rel="noreferrer"
          >
            Github Repo
          </a>
          <a
            href={link}
            className="rounded-3xl font-medium p-1 pr-2 pl-2 border border-green-600 bg-green-600 text-slate-50 hover:bg-green-800"
            target="_blank"
            rel="noreferrer"
          >
            Live Preview
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
