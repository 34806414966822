export const wallpaper_data = [
  {
    id: 1,
    url: "https://pbs.twimg.com/media/GTpwRVqaAAEjs4H?format=jpg&name=large",
    link: "https://drive.google.com/file/d/1uIDzY6hUEVPrWm-8aIdzGkzUANRU8j6w/view?usp=drive_link",
  },
  {
    id: 2,
    url: "https://pbs.twimg.com/media/GTBpcCebAAAsAMn?format=jpg&name=4096x4096",
    link: "https://drive.google.com/file/d/1A1TckoEivEMC4pOQ_RWAaLpjGXb743CV/view?usp=drive_link",
  },
  {
    id: 3,
    url: "https://pbs.twimg.com/media/GS_FgIibMAItGFH?format=jpg&name=4096x4096",
    link: "https://drive.google.com/file/d/16Sc0u8s_ec-pKFoagNlBfBZ1mbnQ0VsT/view?usp=drive_link",
  },
  {
    id: 4,
    url: "https://pbs.twimg.com/media/GQRo9jZaAAEPiGj?format=jpg&name=4096x4096",
    link: "https://drive.google.com/file/d/1NnsQchERxGis_ViUS_jVtP3g84mYHJWM/view?usp=drive_link",
  },
  {
    id: 5,
    url: "https://pbs.twimg.com/media/GOuQc08aMAA8gPh?format=jpg&name=4096x4096",
    link: "https://drive.google.com/file/d/16mhpl8h8jBYpHKVHbkIJGeWkOp8eVKtv/view?usp=drive_link",
  },
  {
    id: 6,
    url: "https://pbs.twimg.com/media/GOjvsjvbQAEw-rZ?format=jpg&name=large",
    link: "https://drive.google.com/file/d/1Ow8oiVYKwWgIhG9QCYr-dC8DjOLZOKFm/view?usp=drive_link",
  },
  {
    id: 7,
    url: "https://pbs.twimg.com/media/GUs_TKVXIAA0jET?format=jpg&name=large",
    link: "https://drive.google.com/file/d/1do8NKqFbePvGXS-634kenSCAS4Xiq8Sj/view?usp=drive_link",
  },
  {
    id: 8,
    url: "https://pbs.twimg.com/media/GXL__GOWsAAe98a?format=jpg&name=medium",
    link: "https://drive.google.com/file/d/1JviNL7Ihpr6eCjzlxrLFDpeB_zA6KmGc/view?usp=sharing",
  },
  {
    id: 9,
    url: "https://pbs.twimg.com/media/GXOUDcoXIAA_0vt?format=jpg&name=large",
    link: "https://drive.google.com/file/d/15bMLJ1pK3Tw8IENQuPvLYFlRPv8PRppS/view?usp=drive_link",
  },
];
