import kitchen from "../assets/kitchen.png";
import youtube from "../assets/youtube.png";
import xCrypto from "../assets/xCrypto.png";
import netflix from "../assets/netflix.png";
import music from "../assets/music.png";
import memoir from "../assets/memoir.png";

const data = [
  {
    id: 10,
    image: memoir,
    title: "Memoir",
    description:
      "A personal journal and storytelling application built with React and Node.js, designed for users to capture and share life moments. It includes features for creating, editing, and organizing memories, with secure user authentication and a sleek, responsive interface.",
    github: "https://github.com/shobhit1812/memoir",
    link: "https://memoir-snowy.vercel.app/",
    techStack: ["Full-Stack", "MERN", "ShadCN-UI"],
  },
  {
    id: 9,
    image: music,
    title: "Master the Art of Music",
    description:
      "Master the Art of Music built with Next.js, focuses on UI, routing, and component development, utilizing Aceternity UI built with Tailwind CSS. It emphasizes a modern, responsive design.",
    github: "https://github.com/shobhit1812/nextjs-project",
    link: "https://nextjs-project-flax-ten.vercel.app/",
    techStack: ["Next.js", "Typescript", "Aceternity UI"],
  },
  {
    id: 8,
    image: netflix,
    title: "NetflixGPT",
    description:
      "Netflix clone built with React, incorporating GPT-3 for generating dynamic content. It includes user authentication, video streaming, and personalized recommendations based on AI interactions.",
    github: "https://github.com/shobhit1812/NetflixGPT",
    link: "https://netflix-gpt-1812.vercel.app/",
    techStack: ["ReactJS", "google firebase", "open ai", "imdb api", "rtk"],
  },
  {
    id: 7,
    image: youtube,
    title: "youtube - clone",
    description:
      "The YouTube Clone mimics YouTube’s core features, using the YouTube Data API to fetch videos, allowing users to search and view video details.",
    github: "https://github.com/shobhit1812/youtube-clone",
    link: "https://youtube-clone1812.vercel.app/",
    techStack: [
      "ReactJS",
      "TailwindCSS",
      "React-Router-DOM",
      "State-Management",
    ],
  },
  {
    id: 6,
    image: kitchen,
    title: "nautiyal's kitchen - food ordering application",
    description:
      "Nautiyal's Kitchen is a responsive food ordering website built with ReactJS, using the SWIGGY API to provide real-time restaurant and menu data.",
    github: "https://github.com/shobhit1812/nautiyals-kitchen",
    link: "https://nautiyals-kitchen.vercel.app/",
    techStack: ["ReactJS", "Redux-Toolkit", "TailwindCSS", "React-Router-DOM"],
  },
  {
    id: 5,
    image:
      "https://github.com/shobhit1812/Reactjs-Chat-App/blob/main/screenshots/Screenshot%20(56).png?raw=true",
    title: "sanvaad - real time chat application",
    description:
      "It features user auth and live chat updates, with a responsive design using Tailwind. This project highlights real-time data handling and secure login.",
    github: "https://github.com/shobhit1812/Reactjs-Chat-App",
    link: "https://sanvaad-app.vercel.app/",
    techStack: ["ReactJS", "NodeJS", "SocketIO", "ExpressJS"],
  },
  {
    id: 4,
    image:
      "https://github.com/shobhit1812/Shop-Electronics-An-ecommerce-app/raw/main/screenshots/Screenshot%20(55).png",
    title: "shop electronics - an Ecommerce app",
    description:
      "It includes user auth, product listings, and a shopping cart, with MongoDB for data storage, showcasing key aspects of a full-stack e-commerce app.",
    github: "https://github.com/shobhit1812/Shop-Electronics-An-ecommerce-app",
    link: "https://shopelctronics.vercel.app/",
    techStack: [
      "ReactJS",
      "NodeJS",
      "MongoDB",
      "ExpressJS",
      "React-Bootstrap",
      "Redux",
    ],
  },
  {
    id: 3,
    image:
      "https://github.com/shobhit1812/Daily-Journal-Blog-Website/raw/main/screenshots/Screenshot%20(55).png",
    title: "blog - daily journal blog",
    description:
      "It enables users to create, read, update, and delete blog posts, with MongoDB for data storage. This project highlights full-stack skills in building a CRUD application.",
    github: "https://github.com/shobhit1812/Daily-Journal-Blog-Website",
    link: "https://daily-journal-blog-website.vercel.app/",
    techStack: [
      "ReactJS",
      "NodeJS",
      "MongoDB",
      "ExpressJS",
      "Redux",
      "JWT-Auth",
    ],
  },
  {
    id: 2,
    image:
      "https://github.com/shobhit1812/SocialPedia/raw/main/screenshots/Screenshot%20(54).png",
    title: "social pedia - social media application",
    description:
      "SocialPedia is a social media app with user authentication and post management. It uses ReactJS, Node.js, Express.js, Material-UI, and MongoDB, showcasing a full-stack platform with real-time features.",
    github: "https://github.com/shobhit1812/SocialPedia",
    link: "https://social-pedia.vercel.app/",
    techStack: ["ReactJS", "Material-UI", "MongoDB", "ExpressJS", "Redux"],
  },
  {
    id: 1,
    image: xCrypto,
    title: "-xCrypto - preview of live cryptocurrency rates",
    description:
      "xCrypto is a cryptocurrency tracker using ReactJS and the CoinGecko API for real-time data. Chakra-UI provides a modern design, showcasing skills in API integration and dynamic data applications.",
    github: "https://github.com/shobhit1812/-xCrypto",
    link: "https://x-crypto.vercel.app/",
    techStack: ["ReactJS", "Chakra-UI", "API-Integration", "React-Router-DOM"],
  },
];

export default data;
