import { createSlice } from "@reduxjs/toolkit";

const hamSlice = createSlice({
  name: "ham",
  initialState: {
    showMenu: false,
  },
  reducers: {
    toggleHam: (state, action) => {
      state.showMenu = !state.showMenu;
    },
  },
});

export const { toggleHam } = hamSlice.actions;

export default hamSlice.reducer;
