import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "../utils/themeSlice";
import { toggleHam } from "../utils/hamSlice";
import { Link } from "react-router-dom";
import { CgDarkMode } from "react-icons/cg";
import { CgMenuMotion } from "react-icons/cg";

const Header = () => {
  const isDark = useSelector((store) => store.theme.darkMode);
  const showMenu = useSelector((store) => store.ham.showMenu);
  const dispatch = useDispatch();

  const toggleAction = () => {
    dispatch(toggleTheme());
  };

  const toggleMenu = () => {
    dispatch(toggleHam());
  };

  const handleNavigation = () => {
    // Close menu after navigating
    if (showMenu) dispatch(toggleHam());
  };

  return (
    <header
      className={`p-5 sticky top-0 backdrop-blur-lg z-50 ${
        isDark ? "shadow-sm shadow-slate-900" : "shadow"
      }`}
    >
      <div className="flex justify-between items-center mx-auto max-w-screen-xl px-6 md:px-16 lg:px-36">
        <Link
          to="/"
          className="font-extrabold text-lg"
          onClick={handleNavigation}
        >
          Shobhit Nautiyal
        </Link>
        <button
          className="text-2xl md:hidden transition-transform duration-300"
          onClick={toggleMenu}
          style={{ transform: showMenu ? "rotate(90deg)" : "rotate(0deg)" }}
        >
          <CgMenuMotion />
        </button>
        <ul
          className={`flex-col md:flex-row md:flex md:items-center transition-all duration-300 ease-in-out ${
            showMenu ? "flex" : "hidden"
          } absolute top-16 left-0 right-0 ${
            isDark ? "bg-slate-950 text-slate-50" : "bg-slate-50 text-slate-950"
          }  md:bg-transparent p-10 md:p-0 md:relative md:top-0 md:left-0 md:right-auto md:w-auto md:h-auto w-full h-screen items-end`}
        >
          <button
            className="px-2 py-1 text-xl mb-4 md:mb-0"
            onClick={toggleAction}
          >
            <CgDarkMode />
          </button>
          <li className="px-2 py-1 font-medium transition duration-300 ease-in-out hover:underline text-2xl md:text-base mb-4 md:mb-0">
            <Link to="/blog" onClick={handleNavigation}>
              blog
            </Link>
          </li>
          <li className="px-2 py-1 font-medium transition duration-300 ease-in-out hover:underline text-2xl md:text-base mb-4 md:mb-0">
            <Link to="/wallpaper" onClick={handleNavigation}>
              wallpaper
            </Link>
          </li>
          <li className="px-2 py-1 font-medium transition duration-300 ease-in-out hover:underline text-2xl md:text-base mb-4 md:mb-0">
            <Link to="/project" onClick={handleNavigation}>
              project
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
