import { useEffect, useState } from "react";
import { wallpaper_data } from "../utils/wallpaperData";
import WallpaperCard from "./WallpaperCard";

const Wallpaper = () => {
  const [blink, setBlink] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setBlink((prevState) => !prevState);
    }, 200);

    return () => clearTimeout(timer);
  }, [blink]);

  return (
    <div className="pt-8 px-6r">
      <h1
        className={`flex flex-wrap justify-center text-lg pb-4 ${
          blink ? "text-red-600" : "text-red-900"
        } `}
      >
        These wallpapers are not owned by me.
      </h1>
      <div className="flex flex-wrap justify-center pb-8">
        {wallpaper_data.map((wallpaper) => (
          <WallpaperCard key={wallpaper?.id} {...wallpaper} />
        ))}
      </div>
    </div>
  );
};

export default Wallpaper;
