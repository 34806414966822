import { useSelector } from "react-redux";
import { createBrowserRouter, Outlet } from "react-router-dom";
import Header from "./components/Header";
import About from "./components/About";
import Blog from "./components/Blog";
import Wallpaper from "./components/Wallpaper";
import Project from "./components/Project";
import ErrorPage from "./components/ErrorPage";
import Footer from "./components/Footer";

const App = () => {
  const isDark = useSelector((store) => store.theme.darkMode);
  const showMenu = useSelector((store) => store.ham.showMenu);

  return (
    <div
      className={`scroll-smooth ${
        isDark ? "bg-slate-950 text-slate-50" : "bg-slate-50 text-slate-950"
      } flex flex-col min-h-screen font-poppins`}
    >
      <Header />
      {<main className="flex-grow">{!showMenu && <Outlet />}</main>}
      <Footer />
    </div>
  );
};

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <About />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/project",
        element: <Project />,
      },
      {
        path: "/wallpaper",
        element: <Wallpaper />,
      },
    ],
  },
]);

export default appRouter;
